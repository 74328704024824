<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
		<mask
			id="mask0_1343_4861"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="24"
			height="25"
		>
			<rect y="0.5" width="24" height="24" fill="#D9D9D9" />
		</mask>
		<g mask="url(#mask0_1343_4861)">
			<path
				d="M5.4 20.1001C4.65 19.3501 4.0625 18.4835 3.6375 17.5001C3.2125 16.5168 3 15.5001 3 14.4501C3 13.4001 3.2 12.3626 3.6 11.3376C4 10.3126 4.65 9.35012 5.55 8.45012C6.13333 7.86679 6.85417 7.36679 7.7125 6.95012C8.57083 6.53345 9.5875 6.20429 10.7625 5.96262C11.9375 5.72095 13.2792 5.57512 14.7875 5.52512C16.2958 5.47512 17.9833 5.53345 19.85 5.70012C19.9833 7.46679 20.025 9.09179 19.975 10.5751C19.925 12.0585 19.7875 13.396 19.5625 14.5876C19.3375 15.7793 19.0208 16.821 18.6125 17.7126C18.2042 18.6043 17.7 19.3501 17.1 19.9501C16.2167 20.8335 15.2792 21.4793 14.2875 21.8876C13.2958 22.296 12.2833 22.5001 11.25 22.5001C10.1667 22.5001 9.10833 22.2876 8.075 21.8626C7.04167 21.4376 6.15 20.8501 5.4 20.1001ZM8.2 19.7001C8.68333 19.9835 9.17917 20.1876 9.6875 20.3126C10.1958 20.4376 10.7167 20.5001 11.25 20.5001C12.0167 20.5001 12.775 20.346 13.525 20.0376C14.275 19.7293 14.9917 19.2335 15.675 18.5501C15.975 18.2501 16.2792 17.8293 16.5875 17.2876C16.8958 16.746 17.1625 16.0376 17.3875 15.1626C17.6125 14.2876 17.7833 13.2293 17.9 11.9876C18.0167 10.746 18.0333 9.26679 17.95 7.55012C17.1333 7.51679 16.2125 7.50429 15.1875 7.51262C14.1625 7.52095 13.1417 7.60012 12.125 7.75012C11.1083 7.90012 10.1417 8.14179 9.225 8.47512C8.30833 8.80845 7.55833 9.26679 6.975 9.85012C6.225 10.6001 5.70833 11.3418 5.425 12.0751C5.14167 12.8085 5 13.5168 5 14.2001C5 15.1835 5.1875 16.046 5.5625 16.7876C5.9375 17.5293 6.26667 18.0501 6.55 18.3501C7.25 17.0168 8.175 15.7376 9.325 14.5126C10.475 13.2876 11.8167 12.2835 13.35 11.5001C12.15 12.5501 11.1042 13.7376 10.2125 15.0626C9.32083 16.3876 8.65 17.9335 8.2 19.7001Z"
				fill="currentColor"
			/>
		</g>
	</svg>
</template>
